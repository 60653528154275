var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.finishInit)?_c('v-data-table',{key:_vm.tableKey,staticClass:"elevation-0 bland-bg",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","items-per-page":25,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.items.meta.totalItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItemsGlobal
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.id))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$lang.status[item.action]))])]}},{key:"item.createdOn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.createdOn)))])]}},{key:"item.userId",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatUser(item.userId)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"color-accent-text",attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.$emit('restoreHistory', item.resource)}}},[_vm._v(" "+_vm._s(_vm.$lang.actions.restore)+" ")])]}}],null,false,2514851097)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }